import { graphql } from "gatsby";
import * as React from "react";
import { MarkdownRemark } from "../graphql-types";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import { withLayout, LayoutProps } from "../components/Layout";

interface PrivacyPageProps extends LayoutProps {
  data: {
    privacy: MarkdownRemark;
  };
}

const PrivacyPage = (props: PrivacyPageProps) => {
  const { html } = props.data.privacy;
  return (
    <Container text>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>
            Privacy
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical
        style={{ border: "none" }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Container>
  );
};

export default withLayout(PrivacyPage);

export const pageQuery = graphql`
  query PrivacyPage {
    privacy: markdownRemark(fields: {slug: {eq: "/privacy/current/"}}) {
      html
    }
  }
`;
